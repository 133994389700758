.our--values {
  background-color: white;

  &--item {
    padding: 16px 16px;
    text-align: center;
    transition: 0.8s ease;
    height: 220px;
    
    @media (max-width: 1200px) {height: 220px}
    @media (max-width: 900px) {height: 220px}
    @media (max-width: 500px) {height: auto}

    &--head {
      margin-bottom: 10px;
      img {
        width: 70px;
      }
    }

    &--content {
      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #1A2B48;
        padding: 12px 0 8px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #1A2B48;
      }
    }

    &:hover {
      background-color: #fafafa;
      -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
      -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
      box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    }
  }

  
  &--web {
    // margin-bottom: 20px;
    @media (max-width: 959px) {
      display: none;
      margin-bottom: 0;
    }
  }

  &--mobile {
    display: none;

    @media (max-width: 959px) {
      display: initial;
      &--tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px  24px 0 ;

        .tabitem {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        .tabitem--inactive {
          opacity: .6;
        }
      }
      &--content {
        padding: 12px 24px 0;
        text-align: center;
          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: #1A2B48;
            padding: 6px 0;
          }
    
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: #1A2B48;
          }
      }
    }
  }
}