.account--nav--list {
  width: 100%;
  


  &--item {
    border: 0.2px solid #f3f3f3;
    text-align: left;
    background-color: #fff;
    // padding: 24px 30px;
    height: 60px;
    width: 100%;
    position: relative;
    @media (max-width: 700px) {
      text-align: center;
    }

    a.active {
      color: #fff;
      background-color: #03387D;
      width: 100%;
    }

    a:hover {
      background-color: #03387D;
      color: #fff;
    }

    a {
      text-decoration: none;
      color: #1a2b48;
      font-size: 14px;
      font-weight: 600;
      position: absolute;
      padding-left: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-self: center;

      
    }
  }
}

.account--edit--infos {
  padding: 10px 0px;
  &--title {
    h3 {
      --minFontSize: 16px;
      --maxFontSize: 22px;
      --scaler: 8vw;
      font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      font-weight: 700;
      color: #1a2b48;
    }
  }
  &--item {
  }
}

.title--area {
  text-align: center;
}

.partner--account--head {
  background-color: #ffffff;
  border: 1px solid #dedede;
  padding: 10px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;



 
    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid #1a2b48;
      margin-right: 15px;
    }
  
    &--icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 15px;
      background-color: #bdbdbd;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: white;
        font-size: 18px;
      }
    }
  
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #1a2b48;
    }



}

.partner--account--head2 {
  background-color: #ffffff;
  border: 1px solid #dedede;
  padding: 10px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &--title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid #1a2b48;
      margin-right: 15px;
    }
  
    &--icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 15px;
      background-color: #bdbdbd;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: white;
        font-size: 18px;
      }
    }
  
    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #1a2b48;
    }

  }

  &--links {
    @media (max-width: 700px) {
      margin-top: 10px;
    }
    &--item {
      margin-right: 10px;
      @media (max-width: 700px) {
        .btn--mobile {
          margin: 5px 0;
          padding: 11px 10px;
          font-size: 13px;
        }
      }

     

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.partner--account--statistics {
  height: 190px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dedede;
  padding: 15px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--left {
    h2 {
      font-size: 40px;
      color: #1a2b48;
      font-weight: 800;
    }
    h4 {
      font-size: 20px;
      margin-top: 20px;
      color: #1a2b48;
      font-weight: 600;
    }
  }

  &--right {
    text-align: right;
    img {
      width: 60px;
      margin-bottom: 10px;
    }

    &--values {
      padding-top: 12px;
      h6 {
        color: #707070;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
      }
      h2 {
        color: #1a2b48;
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
}

.partner--add--item {
  display: flex;
  align-items: center;
  
  .autocomplete  {
    width: 100% !important;
  }


  &--btn {
    height: 100%;
    padding: 22.5px 22px !important;
    margin-left: 11px;
    background-color: #FFB62C !important;
  }
}

.add--program {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.become--partner {
    &--card-item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px;

      &--head {
        padding-bottom: 16px;
      }

      &--content {
        &--title {
          font-size: clamp( 14px, 3vw, 18px);
          font-weight: 700;
          color: $c-main;
          padding-bottom: 12px;
        }
        &--description {
          font-size: clamp( 12px, 3vw, 14px);
          font-weight: 400;
          color: #1A2B48;
          line-height: 24px;
        }
      }
    }

    &--equal {
      padding: 16px 0;

      img {
        margin-bottom: 12px;
      }

      &--infos {
        text-align: center;
        padding-top: 12px;
        h6 {
          font-size: clamp( 14px, 3vw, 18px);
          font-weight: 600;
          color: $c-main;
          line-height: 26px;
          a {
            color: $c-second;
          }
        }
      }
     
    }

}