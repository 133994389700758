.card--event {
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  transition: 0.8s ease;

  .bg--green { background-color: #5caf4c; }
  .bg--yellow { background-color: #FFB62C; }
  .bg--blue { background-color: #5191fa; }

  &:hover {
    -webkit-box-shadow: 1px 0 26px -10px #c2c2c2;
    -moz-box-shadow: 1px 0 26px -10px #c2c2c2;
    box-shadow: 1px 0 26px -10px #c2c2c2;
    transform: scale(1.01);
  }

  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
    .ribbon {
      font: bold 15px sans-serif;
      text-align: center;
      -webkit-transform: rotate(-45deg);
      -moz-transform:    rotate(-45deg);
      -ms-transform:     rotate(-45deg);
      -o-transform:      rotate(-45deg);
      position: relative;
      padding: 7px 0;
      top: 15px;
      left: -30px;
      width: 120px;
      // background-color: #ebb134;
      color: #fff;
    }
  }

  &--image {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 280px;
      object-fit: cover;
      opacity: 1;
      -webkit-transition: 0.7s ease-out;
      -moz-transition: 0.7s ease-out;
      -o-transition: 0.7s ease-out;
      transition: 0.7s ease-out;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &--overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(51, 51, 51, 0) 50%,
        rgba(64, 64, 64, 0.9122023809523809) 100%
      );
    }
    &--top {
      position: absolute;
      top: 20px;
      left: 0;
      padding: 0 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media(max-width: 800px) {
        padding: 0 5px;
    }



    &--type {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
      padding: 4px;
      color: rgb(255, 255, 255);
      font-size: 12px;
      position: absolute;
      top: -20px;
      width: 100%;
      left: 0;
    }

    }

    &--bottom {
      position: absolute;
      bottom: 20px;
      left: 0;
      padding: 0 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--date {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        &--item {
          display: flex;
          align-items: center;
          color: white;
          padding-bottom: 6px;

          &:last-child {
          padding-bottom: 0;
          }
        span {
            font-size: 13px;
            font-weight: 500;
            margin-left: 5px;
        }
        svg {
            font-size: 14px;
        }
        }
      }

      &--count {
        display: flex;
        align-items: center;
        color: white;
        span {
            font-size: 13px;
            font-weight: 500;
            margin-left: 5px;
        }
        svg {
            font-size: 14px;
        }
      }
    }
  }

  &--content {
      padding: 16px 12px 24px 12px;
      height: 160px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    &--head {
        margin-bottom: 20px;
      h1 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #1A2B48;
  //       text-overflow: ellipsis;
  // -webkit-line-clamp: 1;
  // line-clamp: 1;
        display: block;
        // white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h3 {
        font-size: 15px;
        font-weight: 700;
        color: #5191fa;
        position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        margin: 0 auto;
        // right: 0;
        display: inline-block;
        width: 40px;
        border-bottom: #5191fa solid 2px;
      }
    }
    }
    &--circuit {
      display: flex;
      align-items: center;
      padding-bottom: 5px;

      .name {
        display: flex;
        align-items: center;
      }

        span {
          color: #464646;
          font-size: 12px;
            font-weight: 500;
        }
        svg {
            color: #FFB62C;
        }

        p {
            color: #464646;
            font-size: 12px;
            font-weight: 500;
        }
    }
  }
}
.CardPostImage {
  margin-bottom: 1rem;
  position: relative;
}

.CardPostImage img {
  display: block;
  width: 100%;
  height: 280px;
  object-fit: cover;
  opacity: 1;
  -webkit-transition: 0.7s ease-out;
  -moz-transition: 0.7s ease-out;
  -o-transition: 0.7s ease-out;
  transition: 0.7s ease-out;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
}

.CardPostImage span {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f6c385;
  padding: 10px 24px;
  font-size: 1.2rem;
  color: white;
  text-transform: uppercase;
  width: max-content;
  text-align: center;
}
.CardPost {
  // padding: 15px 0;
}

.CardPost h3 {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-weight: 700;
  min-height: 4rem;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

hr {
  border-radius: 50px;
  border: 0.02px solid rgb(244, 244, 244);
}

.CardPost:hover {
  transform: scale3d(1.025, 1.025, 1.025);
  box-shadow: 0 0.6rem 1.6rem 0 rgba(21, 21, 30, 0.2);
}

.CardPost:hover h3 {
  color: #f6c385;
}

.CardPost:hover .CardPostImage img {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.CardPostContent {
  padding: 20px 20px;
}

.CardPostShare {
  padding-top: 12px;
  display: flex;
  align-items: center;
}

.Heading {
  padding: 3rem 0;
}

.HeadingLink {
  font-style: italic;
  font-size: 18px;
  font-weight: 100;
  color: #303030;
  cursor: pointer;
  transition: 0.3s;
}
.HeadingLink:hover {
  color: #f6c385;
  font-style: normal;
  text-decoration: underline;
}
.card--event--content{
  height: auto !important;
  justify-content: flex-start !important;
}
