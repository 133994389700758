:root {
    --primary: #03387D;
    --second: #ffb62c;
    --green: #5CAF4C;
}

.btn {
    /* padding: 8px 24px; */
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: all .3s;

    
}

.btn--link {
    color: var(--primary);
    border: none;
    background-color: white;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--primary);
        color: white;
    }
}

.btn--danger--link {
    color: rgb(152, 17, 2);
    border: none;
    background-color: white;
    &:hover {
        background-color:rgb(152, 17, 2);
        color: white;
    }
}

.btn--prim--opacity {
    background-color: rgba( 3, 56, 124, .09);
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--primary) ;
        color: white;
    }
}
.btn--primary {
    background-color: var(--primary);
    color: #fff;
}

.btn--second {
    background-color: var(--second);
    color: #fff;
}
.btn--green {
    background-color: var(--green);
    color: #fff;
}

.btn--danger {
    background-color: rgb(223, 93, 79);
    color: #fff;
}

.btn--outline {
    background-color: transparent;
    color: var(--primary);
    border: 1.5px solid var(--primary);
    transition: all 0.3s ease-out;
    padding: 8px 20px;
}

.btn--disabled {
    background-color: rgb(173, 173, 173);
    cursor: not-allowed;
    color: white;
    padding: 8px 20px;
    opacity: .5;
}

.btn--small {
   padding: 8px 8px;
    font-size: 12px;
}

.btn--medium {
    padding: 14px 20px;
    font-size: 16px;
   span {
    font-weight: 500;
   }

   @media (max-width: 959px) {
        padding: 12px 12px;
        font-size: 14px;
   }
}
.btn--medium--fullwidth {
    padding: 14px 20px;
    font-size: 16px;
    width: 100%;
   span {
    font-weight: 500;
   }

   @media (max-width: 959px) {
        padding: 12px 12px;
        font-size: 14px;
   }
}

.btn--large {
    padding: 18px 26px;
    height: 56px;
    font-size: 18px;
    width: 100%;
}

.btn--max {
    padding: 18px 26px;
    font-size: 20px;
    width: max-content;
}

.btn--primary:hover {
    background-color: var(--second);
    color: #fff;
    transition: all 0.3s ease-out;
}
.btn--second:hover {
    background-color: var(--primary);
    color: #fff;
    transition: all 0.3s ease-out;
}

.btn--danger:hover {
    background-color: rgb(191, 77, 64);
    color: #fff;
    transition: all 0.3s ease-out;
}

.btn--outline:hover {
    background-color: var(--primary);
    color: #fff;
    transition: all 0.3s ease-out;
}

