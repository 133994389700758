



.font_h1 {
    font-size: 3.8rem;
    line-height: 4rem;
} 
.font_h2 {
    font-size: 3.275rem;
    line-height: 3.475rem;
} 
.font_h3 {
    font-size: 26px;
    line-height: 28px;
} 
.font_h4 {
    font-size: 2.125rem;
    line-height: 2.325rem;
} 
.font_h5 {
    font-size: 1.625rem;
    line-height: 1.825rem;
} 
.font_h6 {
    font-size: 1.15rem;
    line-height: 1.85rem;
} 

.font_h7 {
    font-size: 1rem;
    line-height: 1.2rem;
} 

.font_h8 {
    font-size: .8rem;
    line-height: 1rem;
} 

@media screen and (max-width: 759px) {
    .font_h1 {
        font-size: 2.2rem;
        line-height: 2.4rem;
    } 
    .font_h2 {
        font-size: 2.6rem;
        line-height: 2.775rem;
    } 
    .font_h3 {
        font-size: 26px;
        line-height: 28px;
    } 
    .font_h4 {
        font-size: 1.825rem;
        line-height: 2rem;
    } 
    .font_h5 {
        font-size: 1.625rem;
        line-height: 1.825rem;
    } 
    .font_h6 {
        font-size: 1rem;
        line-height: 1.2;
    } 
}


.font_black {
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
} 
.font_black_italic {
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-style: italic;
} 
.font_extra_bold {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
} 
.font_extra_bold_italic {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-style: italic;
} 
.font_bold {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
} 
.font_bold_italic {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-style: italic;
} 

.font_semi_bold {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
} 
.font_semi_bold_italic {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-style: italic;
} 

.font_regular  {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
} 
.font_regular_italic  {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-style: italic;
} 

.font_light  {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
} 
.font_light_italic  {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-style: italic;
} 


.font_extra_light  {
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
} 
.font_extra_light_italic  {
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    font-style: italic;
} 

