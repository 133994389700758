.collapse__tafwija {
    background-color: white;
    border-radius: 0 !important;
    // border: 1px solid #EDEDED;
    border-bottom: 1px solid #dadada;
    padding: 10px !important;
    // -webkit-box-shadow: 0 0 10px -0 rgba(199, 199, 199, 0.2) !important;
    // -moz-box-shadow: 0 0 10px -0 rgba(199, 199, 199,.2) !important;
    // box-shadow: 0 0 10px -0 rgba(199, 199, 199,.2) !important;
    -webkit-box-shadow: 0 0 10px -0 rgba(236, 236, 236, 0.1) !important;
    -moz-box-shadow: 0 0 10px -0 rgba(236, 236, 236, 0.1) !important;
    box-shadow: 0 0 10px -0 rgba(236, 236, 236, 0.1) !important;
    transition: .8s ease;
    // margin-bottom: 10px;
    width: 100%;

    &:last-child {
        border-bottom: none !important
    }

    &__item {
        cursor: pointer;
    }
    .MuiButtonBase-root {
        background-color: #03387D;
        padding: 0px;
        @media(max-width: 800px) {
            padding: 0px;
        }
    }
    .MuiButtonBase-root:hover {
        background-color: #ffb62c;
    }
    .MuiIconButton-label {
        color: #fff !important;
    } 
    
}