.date--calendar {



  label {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    // transform: translate(-70px, 0px) scale(0.75)
  }
  &--input {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ededed;
    padding: 12px;
    font-size: 16px;
    font-weight: 900;
    width: 100%;
    height: 50px;
    color: rgb(77, 77, 77);
    &::placeholder {
      color: #919191;
      font-weight: 400;
    }
  }
}

.date--calendar--input::placeholder {
  color: #919191 !important;
  font-weight: 400;
}

