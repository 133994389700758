// 
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

@import '../components/header';
@import url('https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css');

:root {

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}


#root {
  background-color: #fff;
}

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  background-color: #fff;
}



.MuiFilledInput-input {
  padding: 13px 12px !important;
  // height: 48px !important; 
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:hover:before,
.MuiFilledInput-underline:after {
  border-bottom: none !important;
  content: none  !important;
}

.MuiFilledInput-root {
  background-color: transparent !important;
  border: 1px solid #ededed;
  width: 100%;
  border-radius: 6px !important;
}
.MuiFilledInput-root:hover {
  background-color: #f8f8f8 !important;
}