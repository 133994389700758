.top--navbar {
  background-color: white;
  color: $c-main;
  padding: 10px 0;
  border-bottom: 1px solid rgba($c-main, .09);

  @media(max-width: 800px) {
    padding: 18px 0;
  }


  &--left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
      @media(max-width: 800px) {
        justify-content: center;
        flex-direction: column;
      }
    &--icons{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      a {
        color: $c-main;
        text-decoration: none;
        background-color: rgba($c-main, .04);
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        border-radius: 4px;
      }
      i {
        color: $c-main;
        font-size: 16px;
      }
    }

    &--offre {
      margin-left: 10px;
      @media(max-width: 800px) { 
        margin-left: 0;
        margin-top: 10px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;

        @media(max-width: 800px) { 
          text-align: center;
          font-size: 11px;
          line-height: 18px;
          font-weight: 500;
        }
      }
    }
  
  }

  &--right{
    text-align: right;
    @media(max-width: 800px) {
      // margin-top: 22px;
    }
    a {
      text-decoration: none;
      color: $c-main;
      padding: 6px 10px;
      margin: 0 8px;
      background-color: rgba($c-main, .04);
      border-radius: 2px;
      span {
        font-size: 13px;
        text-transform: capitalize;
        font-weight: 500;
        margin: 0 5px;
        
      }
      &:last-child {
        margin: 0;
      }
    }

    a:hover {
      background-color: $c-second;
      color: white;
    }

   

    &--name {
      font-size: 12px;
      text-transform: capitalize;
    }

    &--name.mobile {
      display: none;  
      
      @media (max-width: 1010px) {
        display: block;
      }
    }
  }
}

.footer--nav {

  &--right{
    padding-top: 10px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media(max-width: 800px) {
      // margin-top: 22px;
    }
    a {
      text-decoration: none;
      color: white;
      padding: 6px 10px;
      margin: 0;
      border-radius: 2px;
      span {
        font-size: 13px;
        text-transform: capitalize;
        font-weight: 500;
        margin: 0 5px;
        
      }
      &:last-child {
        margin: 0;
      }
    }

    a:hover {
      // background-color: $c-second;
      color: $c-second;
    }

   

    &--name {
      font-size: 12px;
      text-transform: capitalize;
    }

    &--name.mobile {
      display: none;  
      
      @media (max-width: 1010px) {
        display: block;
      }
    }
  }
}


.navbar {
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06),
    1px 3px 8px rgba(39, 44, 49, 0.03);
    @media (max-width: 959px) {
      height: 60px;
    }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  // width: 80%;
  max-width: 1500px;

  // @media (max-width: 959px) {
  //   justify-content: center;
  // }
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 150px;
  }
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0;
  list-style: none;
  text-align: center;
  // width: 60vw;
  justify-content: end;
}

.nav-item {
  height: 80px;
}

.nav-links.active {
  border-bottom: 4px solid $c-second;
  color:  $c-second;
}

.nav-links {
  color: #1A2B48;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 4px solid transparent;

}

.nav-links:hover {
  transition: all 0.2s ease-out;
  border-bottom: 4px solid $c-second;
  color:  $c-second;
}

.fa-bars {
  color: $c-main;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1010px) {

  .nav-item {
    height: 50px;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-links {
    color: #fff;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: $c-main;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    // text-align: center;
    // padding: 1.5rem;
    // width: 100%;
    // display: table;
    text-align: center;
    padding: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-links:hover {
    background-color: $c-main;
    color: #fff;
    border-radius: 0;
  }

  .navbar-logo {
    // position: absolute;
    // top: 0;
    // left: 0;
    // transform: translate(10%, 20%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 80%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: $c-main;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 0rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1rem;
    background-color: transparent;
    color: #fff;
    padding: 12px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: $c-main;
    color: #fff;
    transition: 250ms;
    border: 1px solid rgba(255, 255, 255, 0);

  }
}

.ul-menu {
  display: none;
  transition: all 0.5s ease;
  list-style: none;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06),
    1px 3px 8px rgba(39, 44, 49, 0.03);

    @media (max-width: 959px) {
      display: none;
    }

  li.nav-item {
    padding: 10px;
    a.nav-links {
      text-decoration: none;
      color: #40494b;
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;
      padding: 0.5rem .2rem;
      justify-content: center;
      height: 100%;
      font-size: 14px;
      font-weight: 600;
    }

    &:hover {
      background-color: #e7f7ff;
    }
  }
}
.nav-item:hover .ul-menu {
  display: block;

  @media (max-width: 959px) {
    display: none;
  }
}



.verify--email {
  background-color: rgb(255, 244, 229);
}



.connexion--right {
  &--item {
    padding-right: 10px;

    &:last-child {
      padding-right: 0 ;
    }
  }



  &--item.mobile {
    @media (max-width: 1010px) {
      // button {
      //   padding: 12px 12px;
      //   font-size: 14px;
      // }
      display: none;
    }
  }
}


.menu--mobile {
  
}
.account--mobile { 
  display: none;
  @media (max-width: 1010px) {
    display: block;
  }
}



// ----------------------->
// .header--area {
//   background: #fff;
//   height: 80px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.2rem;
//   box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06),
//     1px 3px 8px rgba(39, 44, 49, 0.03);
// }


// 

.sidebar--menu {

  &--heading {
    height: 60px;
    background-image: linear-gradient(to left, $c-main 90%, $c-second );
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 959px) {
      height: 50px;
    }

    h2 {
      color: white;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .MuiDrawer-paper {
    width: 30%;
    @media (max-width: 959px) {
      width: 80%;
    }
    @media (max-width: 660px) {
      width: 90%;
    }
  }

  .logo {
    margin: 0 auto;
    padding: 8px 0;
    .LogoImg {
      width: 100%;
      height: 45px;
    }
  }

  &--list {
    padding: 16px 0;
    
    &--item {
      height: 50px;
      margin-bottom: 8px;
    

      &--link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        height: 100%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 16px;
        color: $c-main;
        border-left: 6px solid rgba($c-main, .2);
        background: rgba($c-main, .03);
        span {
          font-size: 16px;
          font-weight: 700;
        }
      }
      &--link.active {
        border-left: 6px solid $c-second;
        color: $c-second;
        background: rgba($c-second, .04);
      }
      &--link:hover {
        border-left: 4px solid $c-second;
        color: $c-second;
        background: rgba($c-second, .04);
      }
    }
  }

  &--items {
    padding: 12px 12px;

    .active {
      color: $c-second;
    }
      &--item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 16px;
        text-decoration: none;
        border-radius: 8px;
        background: #fff;
        -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        transition: 0.8s ease;
        width: 100%;
        color: $c-main;
        margin-bottom: 12px;

        @media (max-width: 959px) {
          padding: 12px 16px;
        }

        h3 {
          font-size: 18px;
          font-weight: 700;
        }

       
      }

  }


  &--bottom {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;

      &--item {

        margin-top: 10px;
        button {
          width: 100%;

        }
      }
  }
}


.menu--drawer {
  display: none !important;

  @media (max-width: 1010px) {
    display: block !important;
  }
}

.menu--right {
  display: flex;
  align-items: center;
}