.tafwija--banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // height: 60vh;
    height: 420px;
    @media (max-width: 800px) {
        height: auto;
        padding: 16px 0;
    }

    &--content {
        text-align: center;

        h1 {
            --minFontSize: 24px;
            --maxFontSize: 40px;
            --scaler: 8vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: bold;
            line-height: 52px;
            color: white;
            margin-bottom: 10px;
            @media(max-width: 800px) {
                line-height: 38px;
                margin-top: 10px;

            }
        }
        p {
            --minFontSize: 14px;
            --maxFontSize: 20px;
            --scaler: 5vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 400;
            line-height: 32px;
            color: white;
            @media(max-width: 800px) {
                line-height: 22px;


            }
        }

        margin-bottom: 20px;
    } 
}

@media(max-width: 800px) {
    .mobile-m-top-200 {
        margin-top: 200px;
    }
    .mobile-m-top-250 {
        margin-top: 250px;
    }
}