:root {
    --primary: #03387D;
    --second: #ffb62c;
    --green: #5CAF4C;
}

.link--btn {
    /* padding: 8px 24px; */
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px 16px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    text-decoration: none;

    
}

.link--btn--simple {
    width: 100%;
    height: auto;
}

.link--btn--danger {
    color: rgb(152, 17, 2);
    border: 1px solid rgb(152, 17, 2);
    background-color: white;
    display: flex;
    align-items: center;
    span {
        margin-left: 6px;
    }

    &:hover {
        color: white;
        border: 1px solid rgb(152, 17, 2);
        background-color: rgb(152, 17, 2);;
    }
}

.link--btn--one--noPadding {
    color: var(--primary);
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;

    span {
        margin-left: 6px;
        font-size: 14px;
    }

    &:hover {
        color: var(--second)
    }
}

.link--btn--one {
    color: var(--primary);
    border: none;
    background-color: white;
    display: flex;
    align-items: center;

    span {
        margin-left: 6px;
        font-size: 14px;
    }

    &:hover {
        color: var(--second)
    }
}

.link--btn--two {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    padding: 8px 20px;
    span {
        margin-left: 6px;
    }
    &:hover {
        color: var(--second);
        border: 1px solid var(--second);
    }
}

.link--btn--three {
    background-color: var(--primary);
    color: white;
    transition: all 0.3s ease-out;
    // padding: 8px 20px;
    span {
        margin-left: 6px;
    }
    &:hover {
        color: white;
        background-color: var(--second);

    }
}

.link--btn--four {
    background-color: var(--second);
    color: white;
    transition: all 0.3s ease-out;
    // padding: 8px 20px;
    span {
        margin-left: 6px;
    }
    &:hover {
        color: white;
        background-color: var(--second);
    }
}

.link--btn--disabled {
    background-color: rgb(173, 173, 173);
    cursor: not-allowed;
    color: white;
    padding: 8px 20px;
    opacity: .5;
}

.link--btn--max {
    font-size: 16px;
    width: max-content;
    margin: 0 auto;
    padding: 16px 18px;
    span {
     font-weight: 500;
    }
 
    @media (max-width: 959px) {
         padding: 12px 12px;
         font-size: 14px;
    }
}


.link--btn--medium {
    // padding: 14px 20px;
    font-size: 16px;
   span {
    font-weight: 500;
   }

   @media (max-width: 959px) {
        padding: 12px 12px;
        font-size: 14px;
   }
}
