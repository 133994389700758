.LogoWrapper {
    display: flex;
  align-items: center;
}

.LogoImg {
    width: 90px;
  height: auto;

  @media (max-width: 959px) {
    width: 60px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}