

.auth--wrapper--form {
    padding: 10px 0;
    width: 100%;

    &--content{
        padding-top: 10px;
        &--item{
            margin-bottom: 12px;

            &--resetpassword {
                text-align: right;
                a {
                    color: #03387D;
                    text-decoration: none;
                    font-size: 13px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.MuiIconButton-label {
    color: #03387D !important;
} 


.link--resetpassword {
    text-align: right;
    a {
        color: #03387D;
        text-decoration: none;
        font-size: 13px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.title--card--auth {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #1A2B48;
}




.container--user {
    position: relative;
    z-index: 9;
}
.shape--01 {
    position: fixed;
    right: 0;
    top: 30%;
    z-index: 0;
}

.tafwija--menu {
    overflow: hidden;
    text-align: center;
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid rgba($c-main, .1);

    a {
        align-items: center;
        color: $c-dark;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 3px solid transparent;
        width: max-content;
    }

    a:hover {
        border-bottom: 3px solid $c-second;
        color: $c-second;
    }
      
    a.active {
        border-bottom: 3px solid $c-second;
        color: $c-second;

    }
}