.card--item--post {
 
  cursor: pointer;
  width: 100%;

  transition: 0.8s ease;



  &--image {
    margin: 0 auto;
    position: relative;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    width: 95%;
    img {
      display: block;
      width: 100%;
      height: 300px;
      object-fit: cover;
      opacity: 1;
      -webkit-transition: 0.7s ease-out;
      -moz-transition: 0.7s ease-out;
      -o-transition: 0.7s ease-out;
      transition: 0.7s ease-out;
      border-radius: 10px;
    }
    &:hover {
      -webkit-box-shadow: 1px 0 26px -10px #c2c2c2;
      -moz-box-shadow: 1px 0 26px -10px #c2c2c2;
      box-shadow: 1px 0 26px -10px #c2c2c2;
      transform: scale(1.01);
    }
  }

  &--content {
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    padding: 16px 12px 24px 12px;
    height: auto;
    border-radius: 10px;
    background-color: white;
    text-align: left;
    padding-top: 100px;
    margin-top: -80px;
    &:hover {
      -webkit-box-shadow: 1px 0 26px -10px #c2c2c2;
      -moz-box-shadow: 1px 0 26px -10px #c2c2c2;
      box-shadow: 1px 0 26px -10px #c2c2c2;
    }
  &--head {
      margin-bottom: 20px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 40px;
      color: #1A2B48;
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        // right: 0;
        display: inline-block;
        width: 40px;
        border-bottom: #5191fa solid 2px;
      }
    }
    h3 {
      font-size: 13px;
      font-weight: 500;
      color: #414141;
      position: relative;
      margin-bottom: 15px;
      font-style: italic;
    }
  }
  &--desc {
      p {
          color: #464646;
          font-size: 12px;
          font-weight: 500;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
      }
  }
}

}