.tafwija--checkout--card{
    border-radius: 0;
    background: #fff;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.09);
    transition: 0.8s ease;
    width: 100%;
    height: fit-content;
    padding-top: 10px;

    &--heading {
        background-color: #03387D;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px 15px;

        h2 {
            --minFontSize: 16px;
            --maxFontSize: 24px;
            --scaler: 8vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 700;
            text-align: center;
            color: #ffffff;
        }
        
    }

    &--body { 
        &--p1 {  
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px ;
            border-bottom: 1px solid #E6E6E6;

            h3 {
                font-size: 14px;
                font-weight: 700;
                color: #1A2B48;
            }

            .price {
                font-size: 14px;
                font-weight: 700;
                color: #FFB62C;
            }
        }


        &--p2 { 
            padding: 8px 15px ;
            border-bottom: 1px solid #E6E6E6;
            &--line { 
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;

                h6{
                    font-size: 13px;
                    font-weight: 400;
                    margin-right: 8px;
                }
                p{
                    font-size: 13px;
                    font-weight: 400;
                    color: #03387D;
                }
                
                }
        }


        &--p3 {  
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 15px ;

            h3 {
                font-size: 14px;
                font-weight: 700;
                color: #1A2B48;
            }

            .price {
                font-size: 14px;
                font-weight: 700;
                color: #FFB62C;
            }
        }


        &--p4 {  
            padding: 0 15px;
            margin-bottom: 15px;
            margin-top: 10px;
            &--total { 
                background-color: #F4F4F4;
                border-radius: 8px;
                padding: 15px;
                p {
                    color: #414141;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
    }
}