.card--item--gal {
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
  transition: 0.8s ease;

  &:hover {
    -webkit-box-shadow: 1px 0 26px -10px #c2c2c2;
    -moz-box-shadow: 1px 0 26px -10px #c2c2c2;
    box-shadow: 1px 0 26px -10px #c2c2c2;
    transform: scale(1.01);
  }

  &--image {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 300px;
      object-fit: cover;
      opacity: 1;
      -webkit-transition: 0.7s ease-out;
      -moz-transition: 0.7s ease-out;
      -o-transition: 0.7s ease-out;
      transition: 0.7s ease-out;
      border-radius: 10px;
    }
    &--overlay {
      position: absolute;
      border-radius: 10px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(51, 51, 51, 0) 50%,
        rgba(64, 64, 64, 0.9122023809523809) 100%
      );
    }

    &--bottom {
      position: absolute;
      bottom: 20px;
      left: 0;
      padding: 0 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &--title {
        text-align: center;
        h2 {
          font-size: 20px;
          color: white;
          font-weight: 700;
          line-height: 30px;
        }
      }

    }
  }

}