@import "../../../assets/styles/partials/variables";

.custom__input {
    width: 100%;
    background-color: #fff;
    border: 1px solid #DAE1E7;
    outline: none;

    .MuiFormLabel-root {
        color: $c-dark !important;   
        

    }
    .Mui-focused {
        color: $c-dark !important;
        fieldset {
            border-color: $c-main !important;
        }
    }

    .MuiOutlinedInput-root {
        border-radius: 4px;   
        font-weight: 700 !important;
        outline: none;
        height: 50px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #C5D2E0 !important;

    }
  
   

    
} 

@media(max-width: 800px) {
    .MuiFormLabel-root {
        color: #919191;
        font-weight: 400;
        font-size: 14px !important;
        width: 90%;
      }
}
