.section--auth {
    .MuiPaper-elevation4 {
        box-shadow: none;
    }
    .MuiTab-textColorPrimary {
        font-size: 15px;
        span {
            font-weight: 600 !important;
        }
    }
    .MuiTab-textColorPrimary.Mui-selected {
        color: #03387D;
    }
    .PrivateTabIndicator-colorPrimary-2 {
        background-color: #03387D
    }
}