.badge {
    
    -webkit-box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    padding: 8px 12px;
    height: max-content;
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    color: white;
    border-radius: 50px;
    @media(max-width: 800px) {
        padding: 8px 6px;
    }


    h4 {
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
        @media(max-width: 800px) {
            font-size: 10px;
            margin-left: 6px;
        }
    }
}

.badge--danger {background-color: #ff0000;}
.badge--success {background-color: #007649;}
.badge--pending {background-color: #ffb62c;}