//Setup
@import "partials/normalize";
@import "partials/reset";
@import "partials/variables";
@import "partials/helpers";
@import "partials/mixins";
@import "partials/fonts";
@import "partials/colors";

//Base styles
@import "base/base";

//Component Styles
@import "components/header";
@import "components/footer";
@import "components/menu";
@import "components/date";
@import "components/form";
@import "components/table";
@import "components/values";
@import "components/modal";
@import "components/booking";

// Pages Style
@import "screens/home";
@import "screens/auth";
@import "screens/events";
@import "screens/booking";
@import "screens/account";
@import "screens/galleries";
@import "screens/blog";




// Carousel Gallery



.carousel {
  .carousel--custom--arrow--left {
    position: absolute;
    top: 45%;
    bottom: auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 2;
    left: 1%;
    background-color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .carousel--custom--arrow--right {
    position: absolute;
    top: 45%;
    bottom: auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 2;
    right: 1%;
    background-color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .control-dots {
    .dot {
      transition: opacity .25s ease-in !important;;
      opacity: .5 !important;
      filter: alpha(opacity=30);
      background: #fff;
      border-radius: 50%;
      box-shadow: none !important;
      width: 12px !important;;
      height: 12px !important;;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;
    }

    .dot.selected { 
      opacity: 1!important;

    }
  }
}

// .carousel.carousel-slider .control-arrow {
//   top: 45%;
//     color: $c-main;
//     font-size: 26px;
//     bottom: 0;
//     margin: auto 0;
//     width: 50px;
//     height: 50px;
//     align-items: center;
//     opacity: 1;
//     background-color: white;
//     border-radius: 50px;
//     display: flex;
//     margin-top: 0;
//     padding: 5px;
// }
// .carousel .control-next.control-arrow:before {
//   content: '\203A' !important;
// }

// .carousel .control-prev.control-arrow:before {
//   content: '\203A' !important;
// }


// .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
// }

// Date Picker

.MuiPickersDay-day {
  border-radius: 0 !important;
  margin: .5px !important;
}
.MuiPickersDay-dayDisabled {
  background-color: rgb(232, 232, 232) !important;
  opacity: .5;
  cursor: none;
}
.MuiPickersDay-daySelected {
  background-color: $c-main !important;
  .MuiTypography-colorInherit {
    color: white !important;
  }
}

.MuiPickersBasePicker-pickerView {
  height: 320px !important;
}


// Calendar
.rbc-today {
  background-color: rgba($c-third, 0.2);
}

.rbc-toolbar button {
  border: 1px solid rgb(232, 232, 232) !important;

}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      box-shadow:  0 3px 5px rgb(0 ,0 ,0 , 13%) !important; 
      background-color: $c-main !important; 
      color: white !important;
      &:hover {
        color: white  !important;
      }
}

.rbc-header {
  padding: 12px 8px !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: $c-third !important;
  border-radius: 2px !important;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: $c-third !important;
  border-radius: 2px !important;
}
// END CALENDAR

// ANIMATION
@media screen and (max-width: 800px) {
  .wow{
      animation-name: none !important;
      visibility: visible !important;
  }
}
// END ANIMATION

// SECTION BACKGROUND
.section--1 {
  background-color: rgba($c-main, 0.03)
}
.section--2 {
  background-color: rgba($c-main, $alpha: .1);
  background-image:  linear-gradient(to top,
  rgba($c-second, 0.9),
  rgba($c-main, 0.9)),url('https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1608&q=80');
  background-position: center;
  background-size: cover;
}
.section--3 {
  background-image: linear-gradient(to top,
  rgba($c-main, 0.9),
  rgba($c-third, 0.8)),url('https://images.unsplash.com/photo-1595917513241-e9d7a9d8f0a0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1582&q=80');
  background-position: center;
  background-size: cover;
}
.section--4 {
  background-color: $c-main;
  
  .message--bonus {
    width: 100%;
    text-align: center;
    p {
      color: white;
      font-weight: 600px;
      line-height: 24px;
    }
  }

}

.section--5 {
  background-color: rgba($c-second, 0.03)
}

//----------------------------------------------------------------------------------
.m0auto {
  margin: 0 auto !important;
}
.no-decoration {
  text-decoration: none;
}

.mr-6 {
  margin-right: 6px;
}

.text-center {
  text-align: center;
}

.h-100-100 {
  height: 100%;
}

.w-100-100 {
  width: 100%;
}

.m-top-0 {
  margin-top: 0;
}

.m-top-5 {
  margin-top: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-25 {
  margin-top: 25px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-35 {
  margin-top: 35px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-top-45 {
  margin-top: 45px;
}

.m-top-50 {
  margin-top: 50px;
}

.m-top-55 {
  margin-top: 55px;
}

.m-top-60 {
  margin-top: 60px;
}

.m-top-65 {
  margin-top: 65px;
}

.m-top-70 {
  margin-top: 70px;
}

.m-top-75 {
  margin-top: 75px;
}

.m-top-80 {
  margin-top: 80px;
}

.m-top-85 {
  margin-top: 85px;
}

.m-top-90 {
  margin-top: 90px;
}

.m-top-95 {
  margin-top: 95px;
}

.m-top-100 {
  margin-top: 100px;
}

.m-top-105 {
  margin-top: 105px;
}

.m-top-110 {
  margin-top: 110px;
}

.m-top-115 {
  margin-top: 115px;
}

.m-top-120 {
  margin-top: 120px;
}

.m-top-125 {
  margin-top: 125px;
}

.m-top-130 {
  margin-top: 130px;
}

.m-top-135 {
  margin-top: 135px;
}

.m-top-140 {
  margin-top: 140px;
}

.m-top-145 {
  margin-top: 145px;
}

.m-top-150 {
  margin-top: 150px;
}

.m-right-0 {
  margin-right: 0;
}

.m-right-5 {
  margin-right: 5px;
}

.m-right-10 {
  margin-right: 10px;
}

.m-right-15 {
  margin-right: 15px;
}

.m-right-20 {
  margin-right: 20px;
}

.m-right-25 {
  margin-right: 25px;
}

.m-right-30 {
  margin-right: 30px;
}

.m-right-35 {
  margin-right: 35px;
}

.m-right-40 {
  margin-right: 40px;
}

.m-right-45 {
  margin-right: 45px;
}

.m-right-50 {
  margin-right: 50px;
}

.m-right-55 {
  margin-right: 55px;
}

.m-right-60 {
  margin-right: 60px;
}

.m-right-65 {
  margin-right: 65px;
}

.m-right-70 {
  margin-right: 70px;
}

.m-right-75 {
  margin-right: 75px;
}

.m-right-80 {
  margin-right: 80px;
}

.m-right-85 {
  margin-right: 85px;
}

.m-right-90 {
  margin-right: 90px;
}

.m-right-95 {
  margin-right: 95px;
}

.m-right-100 {
  margin-right: 100px;
}

.m-right-105 {
  margin-right: 105px;
}

.m-right-110 {
  margin-right: 110px;
}

.m-right-115 {
  margin-right: 115px;
}

.m-right-120 {
  margin-right: 120px;
}

.m-right-125 {
  margin-right: 125px;
}

.m-right-130 {
  margin-right: 130px;
}

.m-right-135 {
  margin-right: 135px;
}

.m-right-140 {
  margin-right: 140px;
}

.m-right-145 {
  margin-right: 145px;
}

.m-right-150 {
  margin-right: 150px;
}

.m-bottom-0 {
  margin-bottom: 0 !important;
}

.m-bottom-5 {
  margin-bottom: 5px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.m-bottom-25 {
  margin-bottom: 25px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.m-bottom-35 {
  margin-bottom: 35px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.m-bottom-45 {
  margin-bottom: 45px;
}

.m-bottom-50 {
  margin-bottom: 50px;
}

.m-bottom-55 {
  margin-bottom: 55px;
}

.m-bottom-60 {
  margin-bottom: 60px;
}

.m-bottom-65 {
  margin-bottom: 65px;
}

.m-bottom-70 {
  margin-bottom: 70px;
}

.m-bottom-75 {
  margin-bottom: 75px;
}

.m-bottom-80 {
  margin-bottom: 80px;
}

.m-bottom-85 {
  margin-bottom: 85px;
}

.m-bottom-90 {
  margin-bottom: 90px;
}

.m-bottom-95 {
  margin-bottom: 95px;
}

.m-bottom-100 {
  margin-bottom: 100px;
}

.m-bottom-105 {
  margin-bottom: 105px;
}

.m-bottom-110 {
  margin-bottom: 110px;
}

.m-bottom-115 {
  margin-bottom: 115px;
}

.m-bottom-120 {
  margin-bottom: 120px;
}

.m-bottom-125 {
  margin-bottom: 125px;
}

.m-bottom-130 {
  margin-bottom: 130px;
}

.m-bottom-135 {
  margin-bottom: 135px;
}

.m-bottom-140 {
  margin-bottom: 140px;
}

.m-bottom-145 {
  margin-bottom: 145px;
}

.m-bottom-150 {
  margin-bottom: 150px;
}

.m-left-0 {
  margin-left: 0;
}

.m-left-5 {
  margin-left: 5px;
}

.m-left-10 {
  margin-left: 10px;
}

.m-left-15 {
  margin-left: 15px;
}

.m-left-20 {
  margin-left: 20px;
}

.m-left-25 {
  margin-left: 25px;
}

.m-left-30 {
  margin-left: 30px;
}

.m-left-35 {
  margin-left: 35px;
}

.m-left-40 {
  margin-left: 40px;
}

.m-left-45 {
  margin-left: 45px;
}

.m-left-50 {
  margin-left: 50px;
}

.m-left-55 {
  margin-left: 55px;
}

.m-left-60 {
  margin-left: 60px;
}

.m-left-65 {
  margin-left: 65px;
}

.m-left-70 {
  margin-left: 70px;
}

.m-left-75 {
  margin-left: 75px;
}

.m-left-80 {
  margin-left: 80px;
}

.m-left-85 {
  margin-left: 85px;
}

.m-left-90 {
  margin-left: 90px;
}

.m-left-95 {
  margin-left: 95px;
}

.m-left-100 {
  margin-left: 100px;
}

.m-left-105 {
  margin-left: 105px;
}

.m-left-110 {
  margin-left: 110px;
}

.m-left-115 {
  margin-left: 115px;
}

.m-left-120 {
  margin-left: 120px;
}

.m-left-125 {
  margin-left: 125px;
}

.m-left-130 {
  margin-left: 130px;
}

.m-left-135 {
  margin-left: 135px;
}

.m-left-140 {
  margin-left: 140px;
}

.m-left-145 {
  margin-left: 145px;
}

.m-left-150 {
  margin-left: 150px;
}

.p-left-0 {
  padding-left: 0;
}

.p-left-5 {
  padding-left: 5px;
}

.p-left-10 {
  padding-left: 10px;
}

.p-left-15 {
  padding-left: 15px;
}

.p-left-20 {
  padding-left: 20px;
}

.p-left-25 {
  padding-left: 25px;
}

.p-left-30 {
  padding-left: 30px;
}

.p-left-35 {
  padding-left: 35px;
}

.p-left-40 {
  padding-left: 40px;
}

.p-left-45 {
  padding-left: 45px;
}

.p-left-50 {
  padding-left: 50px;
}

.p-left-55 {
  padding-left: 55px;
}

.p-left-60 {
  padding-left: 60px;
}

.p-left-65 {
  padding-left: 65px;
}

.p-left-70 {
  padding-left: 70px;
}

.p-left-75 {
  padding-left: 75px;
}

.p-left-80 {
  padding-left: 80px;
}

.p-left-85 {
  padding-left: 85px;
}

.p-left-90 {
  padding-left: 90px;
}

.p-left-95 {
  padding-left: 95px;
}

.p-left-100 {
  padding-left: 100px;
}

.p-left-105 {
  padding-left: 105px;
}

.p-left-110 {
  padding-left: 110px;
}

.p-left-115 {
  padding-left: 115px;
}

.p-left-120 {
  padding-left: 120px;
}

.p-left-125 {
  padding-left: 125px;
}

.p-left-130 {
  padding-left: 130px;
}

.p-left-135 {
  padding-left: 135px;
}

.p-left-140 {
  padding-left: 140px;
}

.p-left-145 {
  padding-left: 145px;
}

.p-left-150 {
  padding-left: 150px;
}

.p-right-0 {
  padding-right: 0;
}

.p-right-5 {
  padding-right: 5px;
}

.p-right-10 {
  padding-right: 10px;
}

.p-right-15 {
  padding-right: 15px;
}

.p-right-20 {
  padding-right: 20px;
}

.p-right-25 {
  padding-right: 25px;
}

.p-right-30 {
  padding-right: 30px;
}

.p-right-35 {
  padding-right: 35px;
}

.p-right-40 {
  padding-right: 40px;
}

.p-right-45 {
  padding-right: 45px;
}

.p-right-50 {
  padding-right: 50px;
}

.p-right-55 {
  padding-right: 55px;
}

.p-right-60 {
  padding-right: 60px;
}

.p-right-65 {
  padding-right: 65px;
}

.p-right-70 {
  padding-right: 70px;
}

.p-right-75 {
  padding-right: 75px;
}

.p-right-80 {
  padding-right: 80px;
}

.p-right-85 {
  padding-right: 85px;
}

.p-right-90 {
  padding-right: 90px;
}

.p-right-95 {
  padding-right: 95px;
}

.p-right-100 {
  padding-right: 100px;
}

.p-right-105 {
  padding-right: 105px;
}

.p-right-110 {
  padding-right: 110px;
}

.p-right-115 {
  padding-right: 115px;
}

.p-right-120 {
  padding-right: 120px;
}

.p-right-125 {
  padding-right: 125px;
}

.p-right-130 {
  padding-right: 130px;
}

.p-right-135 {
  padding-right: 135px;
}

.p-right-140 {
  padding-right: 140px;
}

.p-right-145 {
  padding-right: 145px;
}

.p-right-150 {
  padding-right: 150px;
}

.p-top-0 {
  padding-top: 0;
}
.p-top-2 {
  padding-top: 2px;
}
.p-top-5 {
  padding-top: 5px;
}

.p-top-10 {
  padding-top: 10px;
}

.p-top-15 {
  padding-top: 15px;
}

.p-top-20 {
  padding-top: 20px;
}

.p-top-25 {
  padding-top: 25px;
}

.p-top-30 {
  padding-top: 30px;
}

.p-top-35 {
  padding-top: 35px;
}

.p-top-40 {
  padding-top: 40px;
}

.p-top-45 {
  padding-top: 45px;
}

.p-top-50 {
  padding-top: 50px;
}

.p-top-55 {
  padding-top: 55px;
}

.p-top-60 {
  padding-top: 60px;
}

.p-top-65 {
  padding-top: 65px;
}

.p-top-70 {
  padding-top: 70px;
}

.p-top-75 {
  padding-top: 75px;
}

.p-top-80 {
  padding-top: 80px;
}

.p-top-85 {
  padding-top: 85px;
}

.p-top-90 {
  padding-top: 90px;
}

.p-top-95 {
  padding-top: 95px;
}

.p-top-100 {
  padding-top: 100px;
}

.p-top-105 {
  padding-top: 105px;
}

.p-top-110 {
  padding-top: 110px;
}

.p-top-115 {
  padding-top: 115px;
}

.p-top-120 {
  padding-top: 120px;
}

.p-top-125 {
  padding-top: 125px;
}

.p-top-130 {
  padding-top: 130px;
}

.p-top-135 {
  padding-top: 135px;
}

.p-top-140 {
  padding-top: 140px;
}

.p-top-145 {
  padding-top: 145px;
}

.p-top-150 {
  padding-top: 150px;
}

.p-bottom-0 {
  padding-bottom: 0;
}

.p-bottom-5 {
  padding-bottom: 5px;
}

.p-bottom-10 {
  padding-bottom: 10px;
}

.p-bottom-15 {
  padding-bottom: 15px;
}

.p-bottom-20 {
  padding-bottom: 20px !important;
}

.p-bottom-25 {
  padding-bottom: 25px;
}

.p-bottom-30 {
  padding-bottom: 30px;
}

.p-bottom-35 {
  padding-bottom: 35px;
}

.p-bottom-40 {
  padding-bottom: 40px;
}

.p-bottom-45 {
  padding-bottom: 45px;
}

.p-bottom-50 {
  padding-bottom: 50px;
}

.p-bottom-55 {
  padding-bottom: 55px;
}

.p-bottom-60 {
  padding-bottom: 60px;
}

.p-bottom-65 {
  padding-bottom: 65px;
}

.p-bottom-70 {
  padding-bottom: 70px;
}

.p-bottom-75 {
  padding-bottom: 75px;
}

.p-bottom-80 {
  padding-bottom: 80px;
}

.p-bottom-85 {
  padding-bottom: 85px;
}

.p-bottom-90 {
  padding-bottom: 90px;
}

.p-bottom-95 {
  padding-bottom: 95px;
}

.p-bottom-100 {
  padding-bottom: 100px;
}

.p-bottom-105 {
  padding-bottom: 105px;
}

.p-bottom-110 {
  padding-bottom: 110px;
}

.p-bottom-115 {
  padding-bottom: 115px;
}

.p-bottom-120 {
  padding-bottom: 120px;
}

.p-bottom-125 {
  padding-bottom: 125px;
}

.p-bottom-130 {
  padding-bottom: 130px;
}

.p-bottom-135 {
  padding-bottom: 135px;
}

.p-bottom-140 {
  padding-bottom: 140px;
}

.p-bottom-145 {
  padding-bottom: 145px;
}

.p-bottom-150 {
  padding-bottom: 150px;
}

.h-5 {
  height: 5px;
}

.h-10 {
  height: 10px;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px;
}

.h-35 {
  height: 35px;
}

.h-40 {
  height: 40px;
}

.h-45 {
  height: 45px;
}

.h-50 {
  height: 50px;
}

.h-55 {
  height: 55px;
}

.h-60 {
  height: 60px;
}

.h-65 {
  height: 65px;
}

.h-70 {
  height: 70px;
}

.h-75 {
  height: 75px;
}

.h-80 {
  height: 80px;
}

.h-85 {
  height: 85px;
}

.h-90 {
  height: 90px;
}

.h-95 {
  height: 95px;
}

.h-100 {
  height: 100px;
}

.h-105 {
  height: 105px;
}

.h-110 {
  height: 110px;
}

.h-115 {
  height: 115px;
}

.h-120 {
  height: 120px;
}

.h-125 {
  height: 125px;
}

.h-130 {
  height: 130px;
}

.h-135 {
  height: 135px;
}

.h-140 {
  height: 140px;
}

.h-145 {
  height: 145px;
}

.h-150 {
  height: 150px;
}

.clear {
  clear: both;
}

.no-mar {
  margin: 0 !important;
}

.no-mar-bot {
  margin: 0px !important;
}

.no-pad {
  padding: 0 !important;
}

//----------------------------------------------------------------------------------


// SCROLL
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #1A2B48;
  border: 0px none #ffffff;
}
// ::-webkit-scrollbar-thumb:hover {
//   background: #ffffff;
// }
::-webkit-scrollbar-thumb:active {
  background: #919191;
}
::-webkit-scrollbar-track {
  background: #e9e9e9;
  border: 0px none #ffffff;
}
// ::-webkit-scrollbar-track:hover {
//   background: #1A2B48;
// }
::-webkit-scrollbar-track:active {
  background: #1A2B48;
}
::-webkit-scrollbar-corner {
  background: transparent;
}