.type {
    padding: 6px 10px;
    height: max-content;
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    color: white;
    border-radius: 50px;
    @media(max-width: 800px) {
        padding: 4px 6px;
    }

    h4 {
        font-size: 13px;
        font-weight: 500;
        @media(max-width: 800px) {
            font-size: 10px;
        }
    }
}

.voyage {background-color: #1294d5;}
.activity {background-color: #ffb62c;}