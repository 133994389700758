.carousel_item {
  padding: 10px;
  @media(max-width: 800px) {
    padding: 0px;
}
}

.react-multiple-carousel__arrow {
  background-color: #fff !important;
} 
.react-multiple-carousel__arrow::before {
  color: #000 !important;
}
.react-multiple-carousel__arrow--right {
  right: 15px !important;
  z-index: 8;
}
.react-multiple-carousel__arrow--left {
  left: 15px !important;
  z-index: 8;
}

.react-multi-carousel-dot--active button {
  background-color: #ffb62c;
}
.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(237, 237, 237);
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}

.carousel-container {
  padding-bottom: 30px;
}

