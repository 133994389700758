.tafwija--searchbar {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
    box-shadow: 8px 14px 38px rgba(39, 44, 49, 0.06), 1px 3px 8px rgba(39, 44, 49, 0.03);
    margin: 10px 0 ;
    padding: 16px 10px;
    border-radius: 8px;
    border: none;
    position: relative;
    @media(max-width: 800px) {
        padding: 6px 6px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
    // .item {
    //     // display: flex;
    //     // flex-direction: column;
    //     width: 100%;
    //     label {
    //         color: rgba(0, 0, 0, 0.54);
    //         padding: 0;
    //         font-size: .8rem;
    //         font-weight: 400;
    //         margin-bottom: 8px;
    //         line-height: 1;
    //         letter-spacing: 0.00938em;
    //         // transform: translate(-70px, 0px) scale(0.75)
    //       }
    // }

    form {
        width: 100%;
    }

    .selectbox--item {
        width: 100%;

        .MuiFilledInput-root {
            height: 56px;
            border: none !important
        }
    }

    .button {
        .button-search {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #03387D;
            height: 100%;
            border: none;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
            padding: 0 16px;
            color: white;
            font-weight: 500;

            @media(max-width: 900px) {
               position: static;
               border-radius: 6px;
               outline: none;
               border: none;
               cursor: pointer;
               text-align: center;
               padding: 18px 26px;
               
               width: 100%;
               background-color: #03387D;
               text-decoration: none;
               display: inline-block;
               cursor: pointer;
           
            }
        }
        @media(max-width: 800px) {
            width: 100%;
            margin-top: 10px;
        }
    }
    
}