.primary_c {
  color:  $c-main
}
.primary_dark_c {
  color:  $c-main-dark
}
.second_c {
  color:  $c-second
}
.black_c {
  color:  $c-black
}
.white_c {
  color:  $c-white
}
.gray_light_c {
  color:  $c-gray-light
}
.gray_c {
  color:  $c-gray
}
.gray_l2_c {
  color:  $c-gray-l2
}
.gray_ab_c {
  color:  $c-grey-ab
}
.blue_dark_c {
  color:  $c-blue-dark
}