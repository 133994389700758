
.status--wrap {
    display: flex;
    align-items: center;

    h4 {
        padding-left: 12px;
        --minFontSize: 13px;
        --maxFontSize: 16px;
        --scaler: 2vw;
        font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
        font-weight: 600;
        margin-bottom: 0;
    }

    svg {
        font-size: 20px;
    }
}
.status--canceled {color: #ff0000;}
.status--confirmed {color: #00b115;}
.status--pending {color: #ffb62c;}