.tafwija--modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  
  &--card {
    min-width: 400px;
    min-height: 200px;
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    @media (max-width: 700px) {
      min-width: auto;
      min-height: auto;
      padding: 16px 16px;
      overflow: auto;
    }

    &--head {
      position: relative;
      padding-bottom: 24px;
      text-align: center;

      &--close {
        position: absolute;
        right: 0px;
        top: 0px;
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      &--title {
        --minFontSize: 18px;
        --maxFontSize: 24px;
        --scaler: 4vw;
        font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
        font-weight: 700;
        color: $c-main;
        padding-bottom: 16px;
      }
      &--subtitle {
        --minFontSize: 12px;
        --maxFontSize: 14px;
        --scaler: 4vw;
        font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
        font-weight: 500;
        line-height: 24px;
        color: #1A2B48;
      }

    }

    &--body {

      // .btn--facebook {
      //   height: 50px;
      //   padding: 0 16px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   position: relative;
      //   background: $c-main;
      //   text-decoration: none;
      //   border-radius: 4px;
      //   svg {
      //     position: absolute;
      //     left: 12px;
      //     color: white;
      //   }
      //   span {
      //     font-size: 16px;
      //     font-weight: 600;
      //     color: white;
      //   }
      // }

      // .btn--google {
      //   height: 50px;
      //   padding: 0 16px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   position: relative;
      //   border: 2px solid #DCDFE4;
      //   text-decoration: none;
      //   border-radius: 4px;
      //   svg {
      //     position: absolute;
      //     left: 12px;
      //   }
      //   span {
      //     font-size: 16px;
      //     font-weight: 600;
      //     color: $c-main;
      //   }
      // }

      .btn--mail {
        height: 50px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: $c-second;
        text-decoration: none;
        border-radius: 4px;
        transition: .5s all;
      
        svg {
          position: absolute;
          left: 12px;
          color: white;
        }
        span {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }

     

      &--editCancel {
        width: 100%;
      }

      &--cancel {
        .MuiListItem-gutters {
          padding-left: 0;
          padding-right: 0;
        }
      }

    }

    &--footer {
      border-top: 1px solid #EBEEF1;
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-size: 16px;
    }
  }
}

.modal--small {
  width: 400px;
  @media (max-width: 600px) {
    width: 92%;
  }
}
.modal--medium {
  width: 560px;
  .tafwija--modal--card--head  {
    text-align: left;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
}
.modal--large {
  max-width: 1280px;
  width: 100%;
  @media (max-width: 600px) {
    width: 90%;
    height: 80%;
  }
}



.btn--custom {
  position: relative;

  &--icon {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
  }
}


.btn--facebook {
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $c-main;
  text-decoration: none;
  border-radius: 4px;
  svg {
    position: absolute;
    left: 12px;
    color: white;
  }
  span {
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
}

.btn--google {
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid #DCDFE4;
  text-decoration: none;
  border-radius: 4px;
  svg {
    position: absolute;
    left: 12px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
    color: $c-main;
  }
}

.divider--or {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #858995;

  &::before,
  &::after {
    position: absolute;
    width: 40%;
    height: 1.5px;
    top: 24px;
    background-color: #EBEEF1;
    content: '';
  }
  
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}