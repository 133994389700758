.footer--section {
  background-color: #03387D;

  &--item {
    &--insta {
      position: relative;

      img {
        border-radius: 6px;
        width: 100%;
      }
      &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        background: rgba(250, 250, 250, 0.345);
        display: flex;
        align-items: center;
        justify-content: center;

        &--tag {
          font-size: 24px;
          font-weight: 800;
          color: #0083C9;
          text-decoration: none;
          background-color: rgba(#dfdfdf, 0.7);
          padding: 10px 24px;
          border-radius: 100px;
          width: max-content;
            i {
              margin-left: 10px;
              font-size: 24px;
              font-weight: 800;
            }
          
          
        }
      }

    }
    img {
    }

    .logo-footer {
      width: 200px;
    }

    &--description {
      padding-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: white;
      line-height: 24px;
    }

    &--p1 {
      &--head {
        font-size: 22px;
        font-weight: 700;
        color: white;
        line-height: 30px;
        padding-bottom: 6px;
      }
      &--icons {
        display: flex;
        align-items: center;

        &--icon {
          margin-right: 6px;
          text-decoration: none;
          border-radius: 6px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          i {
            font-size: 20px;
          }
        }
        .fb {
          background-color: #2061b1;
        }
        .insta {
          background-color: #d9567a;
        }
        .in {
          background-color: #4062ab;
        }
      }
      &--p {
        font-size: 14px;
        font-weight: 400;
        color: white;
        line-height: 24px;
      }
    }
  }

  &--bottom {
    text-align: center;
    border-top: 1px solid #7086ac;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: rgb(237, 237, 237);
    }

    a {
      color: #ffb62c;
      text-decoration: none;
      font-weight: 500;
    }
  }

  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
      box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
  }

  @keyframes shadow-pulse-big {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    }

    100% {
      box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
    }
  }

  .notif-whatsapp {
    background-color: #5aa036;

    height: 3rem;
    width: 3rem;
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.2);
    text-align: center;
    cursor: pointer;
    animation: shadow-pulse 1s infinite;
  }

  .notif-whatsapp .icon {
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    position: absolute;
    top: 0.4rem;
    right: 0rem;
    border-radius: 50%;
    z-index: 2000;
    /* box-shadow: 0 1rem 3rem rgba(0,0,0,0.2); */
    text-align: center;
    cursor: pointer;

    i {
      color: #fff;
    }
  }
}
