.wrapper {
  position: relative;
  z-index: 1;
  width: max-content;

  &:hover .highlight {
    transform: scaleY(3.5);
  }

  .highlight {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 5px;
    background: rgba(#ffb62c, .5);
    transform-origin: 0% 100%;
    transition: transform 0.25s;
  }

  .thelink {
    position: relative;
    z-index: 2;
    --minFontSize: 24px;
    --maxFontSize: 40px;
    --scaler: 2vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
    font-weight: 700;
  }

  .primary {
    color: #03387D;
  }
  .white {
    color: #fff;
  }
}
