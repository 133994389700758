.section--blog {


  &--image {
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    border-radius: 10px;


     img{
      border-radius: 10px;
      width: 100%;
      height: 500px;

    }
  }


  &--content {
    
    padding-top: 40px;

    &--head {

      display: flex;
      align-items: center;
      justify-content: space-between;


      h2 {
        color: #1A2B48;
        font-size: 28px;
        font-weight: 700;
      }
    }

    &--description {
      padding-top: 20px;
      .content {
        line-height: 30px;

      }
      p{
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }

}
