.booking--card {
  --minMarginBottom: 8px;
  --maxMarginBottom: 16px;
  --scaler: 2vw;
  margin-bottom: clamp( var(--minMarginBottom), var(--scaler), var(--maxMarginBottom) );

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    &--left {
      display: flex;
      align-items: center;

      &--image {
        img {
          width: 70px;
          height: 70px;
          object-fit: fill;
          border-radius: 6px;
        }
      }
      &--title {
        padding-left: 16px;
        &--txt {
          padding-bottom: 16px;
          display: flex;
          align-items: center;
          span {
            --minFontSize: 14px;
            --maxFontSize: 16px;
            --scaler: 2vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 700;
            color: #1A2B48;
            margin-right: 8px;
          }
         
        }
      }
    }
    &--right {
      @media (max-width: 800px) {
        text-align: center;
        width: 100%;
        padding-top: 12px;
      }
    }
  }

  &--infos {
    padding: 16px 0;
  }

  &--toggle {
    border-top: 1px solid rgba(#083A7B, .2);
    padding-top: 12px;
    margin-top: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    color: #03387D;
    cursor: pointer;
  }
}