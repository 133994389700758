.searchBar--events {
    margin-top: -60px;
}

.mobile--experience {
    @media(max-width: 991px) {
        display: block;
        padding-top: 12px;
    }
    @media(min-width: 992px) {
        display: none;
    }

    &--title {
        font-size: clamp( 14px, 3vw, 16px);
        color: #101b2e;
        font-weight: 600;
        line-height: 24px;
    }
    
    &--item {
        padding: 25px 0px;
        display: flex;
        align-items:  flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #dee4eb;

        @media (max-width: 991px) {
            padding: 16px 0px;
        }
        &:last-child {
            border-bottom: none;
        } 
        

        &--value {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 4px;
            
            &:last-child {
                padding-bottom: none;
            } 

            svg {
                margin-right:  12px;
            }

            &--text {
                font-size: clamp( 13px, 3vw, 16px);
                color: #1A2B48;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .valuee{
            width: 80%;
            padding-left: 8px;
            @media (max-width: 991px) {
                width: 100%;
            }
        }

        h4 {
            width: 20%;
            font-weight: 500;
            color: #1A2B48;
            line-height: 24px;
            @media (max-width: 991px) {
                width: 90%;
            }
        }

        p,ul li {
            font-size: 15px;
            font-weight: 300;
            line-height: 28px;

            svg {
                font-size: 15px;

            }

            .included { color: rgb(0, 186, 46); }
            .noincluded { color: red; }
        }

        @media (max-width: 700px) {
            flex-direction: column;

            h4 {
                width: 100%;
                margin-bottom: 10px;
            }

            p,ul li {
                width: 100%;
            }
        }
    }
    

    &--short {
        padding: 25px 0px;
        display: flex;
        align-items:  flex-start;
        justify-content: flex-start;
        @media (max-width: 991px) {
            padding: 16px 0px;
            flex-direction: column;
        }

        h4 {
            width: 20%;
            font-weight: 500;
            color: #1A2B48;
            line-height: 24px;
            @media (max-width: 991px) {
                width: 100%;
            }
        }

        &--content {
            width: 80%;
            padding-left: 8px;
            @media (max-width: 991px) {
                width: 100%;
                padding-left: 0px;
            }

            &--text {
                font-size: clamp( 12px, 3vw, 16px)  !important;
                color: #1A2B48;
                font-weight: 400  !important;
                line-height: 28px !important;
            }
        }
    }
}

.web--experience {
    @media(max-width: 991px) {
        display: none;
    }
}
.detail--event--wrapper {
    width: 100%;

    @media(max-width: 800px) {
        padding: 0px;
    }

    &--imagee {
        width: 100%;
        object-fit: cover;
    }

    &--head {
        border-bottom: 1px solid #dee4eb;
        &--title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            h1 {
                --minFontSize: 18px;
                --maxFontSize: 26px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 800;
                color: $c-main;
                line-height: 32px;
            }
        }

        &--paths {
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #1A2B48;
            font-weight: 300;
            font-size: 12px;
    
            p {
                margin-right: 8px;
                &:last-child {
                    margin-right: 8px;
                }
            }
        }

        &--infos {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 16px;
        }

        &--reviewGifts {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 16px 0;
            
            &--rating {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                @media(max-width: 500px) {
                    padding-bottom: 12px;
                }
                p {
                    padding-left: 8px;
                    color: #03387D;
                    font-size: 13px;
                }
            }
        }
    }
    &--specific {
        border-top: 1px solid #D0D0D0;
        border-bottom: 1px solid #D0D0D0;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media(max-width: 800px) {
            padding: 15px 0px;
        }
        
        &--item {
            text-align: center;
            @media(max-width: 800px) {
               width: 50%;
               margin-bottom: 15px;
            }
            

            svg {
                color: #1C2B46;
                font-size: 36px;
            }
            h5 {
                color: #1C2B46;
                font-size: 14px;
                font-weight: 500;
                padding-top: 15px;
            }
        }
    }

    &--aboutEvent {
        border-bottom: 1px solid #dee4eb;
        border-top: 1px solid #dee4eb;
        padding: 24px 0;

        &--head {
            padding-bottom: 16px;
        }
        &--item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 24px;
            

            &--icon {
                padding-right: 12px    
            }
            &--content {
                &--title {
                    font-size: clamp( 14px, 3vw, 16px );
                    color: #1A2B48;
                    font-weight: 500;
                    padding-bottom: 8px;
                }
                &--text {
                    font-size: clamp( 12px, 3vw, 14px );
                    color: #505153;
                    font-weight: 400;
                }
            }
        }
    }

    &--descriptions {
        padding: 30px 0;
        overflow: auto;
        line-height: 30px;
    }

    &--programs {
        padding: 30px 0;
    
        .program--title {
            --minFontSize: 14px;
            --maxFontSize: 18px;
            --scaler: 8vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 600;
            color: #1A2B48;
        }

        .program--content {
            line-height: 35px;
        }
        
    }

    &--features {
        border-bottom: 1px solid #dee4eb;
        border-top: 1px solid #dee4eb;
        padding: 24px 0;
        
        &--item {
            padding: 25px 0px;
            display: flex;
            align-items:  flex-start;
            justify-content: flex-start;
            border-bottom: 1px solid #dee4eb;

            @media (max-width: 991px) {
                padding: 16px 0px;
            }
            &:last-child {
                border-bottom: none;
            } 
            

            &--value {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-bottom: 4px;
                
                &:last-child {
                    padding-bottom: none;
                } 

                svg {
                    margin-right:  12px;
                }

                &--text {
                    font-size: clamp( 14px, 3vw, 18px);
                    color: #1A2B48;
                    font-weight: 600;
                }
            }

            .valuee{
                width: 80%;
                padding-left: 8px;
                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            h4 {
                width: 20%;
                font-weight: 500;
                color: #1A2B48;
                line-height: 24px;
                @media (max-width: 991px) {
                    width: 90%;
                }
            }

            p,ul li {
                font-size: 15px;
                font-weight: 300;
                line-height: 28px;

                svg {
                    font-size: 15px;

                }

                .included { color: rgb(0, 186, 46); }
                .noincluded { color: red; }
            }

            @media (max-width: 700px) {
                flex-direction: column;

                h4 {
                    width: 100%;
                    margin-bottom: 10px;
                }
    
                p,ul li {
                    width: 100%;
                }
            }
        }
        

        &--short {
            padding: 25px 0px;
            display: flex;
            align-items:  flex-start;
            justify-content: flex-start;
            @media (max-width: 991px) {
                padding: 16px 0px;
                flex-direction: column;
            }

            h4 {
                width: 20%;
                font-weight: 500;
                color: #1A2B48;
                line-height: 24px;
                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            &--content {
                width: 80%;
                padding-left: 8px;
                @media (max-width: 991px) {
                    width: 100%;
                    padding-left: 0px;
                }

                &--text {
                    font-size: clamp( 12px, 3vw, 16px)  !important;
                    color: #1A2B48;
                    font-weight: 400  !important;
                    line-height: 28px !important;
                }
            }
        }


    }

    &--noborder {
        padding: 24px 0;
    }

    .head {
        --minFontSize: 16px;
        --maxFontSize: 26px;
        --scaler: 8vw;
        font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
        font-weight: 600;
        color: #1A2B48;
        margin-bottom: 15px;
    }
}

.gallery--carousel {
    width : 100%; 
    border: 2px solid white;
    margin-right: 2px;
    margin-left: 2px;
    // height: 500px;

   
  
    &--img {
      width : 100%; 
      min-height: 400px;
      max-height: 500px; 
      height: 500px;
      object-fit: contain;
      object-position: center center;
      

      @media (max-width: 600px) {
        min-height: 400px;
        max-height: 280px; 
        height: 280px;
        object-fit: unset;
      }
    }
  }
  

.link--events {
    text-decoration: none;
    color: initial;
    width: 100%;
}

.events--circuit {
    &--divider {
        &:last-child{}
    }
 
}




.card--booking {
    // border-radius: 10px;
    background: #fff;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    transition: 0.8s ease;
    width: 100%;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    @media (max-width: 959px) {
        display: none;
    }
    &--heading {
        padding: 20px 16px;
        h6 {
            font-size: 12px;
            font-weight: 300;
            color: #939393;
            padding-bottom: 10px;
        }
        h2 {
            --minFontSize: 16px;
            --maxFontSize: 24px;
            --scaler: 8vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 700;
            color: #1A2B48;

            span {
                font-size: 14px;
                font-weight: 400;
                color: #1A2B48;
            }
        }
       
    }

    &--body { 
        padding: 16px;;
        
        &--item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 10px;

            span {
                padding-left: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #1A2B48;
            }
        }
    }

    &--footer {
        padding: 0 20px 30px 20px;

    }
}

.card--booking--mobile { 
    display: none;
}

.card--booking--mobilebtn {
    display: none;
    @media (max-width: 959px) {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #fff;
        -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        transition: 0.8s ease;
        width: 100%;
        padding: 12px;
        z-index: 99;
    }
}

.mobile--price {
    display: none;
    @media (max-width: 959px) {
        display: block;
        padding-bottom: 20px;
    }
    .card--booking--mobile {
        background: #fff;
        margin-top: 16px;
        border: 2px solid #eeeeee;
        -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        transition: 0.8s ease;
        width: 100%;
        display: none;

        @media (max-width: 959px) {
            display: block;
        }

        &--heading {
            padding: 16px;
            h6 {
                font-size: 12px;
                font-weight: 300;
                color: #1A2B48;
                padding-bottom: 10px;
            }
            h2 {
                --minFontSize: 14px;
                --maxFontSize: 18px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 700;
                color: #1A2B48;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #1A2B48;
                }
            }
        
        }

        &--body { 
            padding: 16px;;
            
            &--item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-bottom: 10px;

                span {
                    padding-left: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #1A2B48;
                }
            }
        }

        &--footer {
            padding: 0 20px 30px 20px;

        }
    }
}


.card--organizer {
    border-radius: 0;
    background: #fff;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    transition: 0.8s ease;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &--heading {
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        &--left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            img {
                margin-right: 10px;
                width: 50px;
                height: 50px;
                border: 1px solid #e9e9e9;
                border-radius: 50px;
            }
            h2 {
                --minFontSize: 12px;
                --maxFontSize: 14px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 600;
                color: #1A2B48;
            }
        }
    }

    &--body { 
        text-align: left;
        padding: 5px 15px;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #1A2B48;
            padding-bottom: 10px;
        }   
    } 
}

.card--review {
    border-radius: 0;
    background: #fff;
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    transition: 0.8s ease;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &--heading {
        background-color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px;

        &--left {
            h2 {
                --minFontSize: 12px;
                --maxFontSize: 18px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 600;
                color: #1A2B48;
            }
        }
    }

    &--body { 
        text-align: left;
        padding: 0px 16px ;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #1A2B48;
            padding-bottom: 10px;
        }   
    } 

    &--footer {
        padding: 0px 16px 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &--custom {
            margin-right: 12px;
            border-radius: 50px;
            width: 40px;
            height: 40px;
            background-color: $c-second;
            color: white;
            align-items: center;
            justify-content: center;
            display: flex;
            text-transform: uppercase;
            font-weight: 800;
        }
        img {
            margin-right: 12px;
            width: 40px;
            border-radius: 40px;
            height: 40px;
            object-fit: cover;
        }
        h6 {
            font-size: clamp( 13px, 3vw , 16px );
            font-weight: 600;
            color: #1A2B48;
           
        }
    }
}

.right--box {
    // height: 200vh;
    width: 100%;
    top: 6rem;
    z-index: 1;
    height: auto;

    @media (max-width: 959px) {
        display: none;
    }
}
.box--sticky {
    position: -webkit-sticky;
    position: sticky;
    // top: 0;
}




.social-single {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;

    span {
        padding-right: 10px;
    }
    ul {
        display: flex;
        align-items: center;
    }
    li  {
        display: flex;
        align-items: center;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    
        a {
          margin-right: 6px;
          text-decoration: none;
          border-radius: 6px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
    
          i {
            font-size: 20px;
          }
        }
        .fb {background-color: #2061b1;}
        .w {background-color: #03b347;}
        .in {background-color: #4062ab;}
    }
}
