.section--galleries {


  .carousel-container {
    padding-bottom: 0;
  }

  &--images {
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    border-radius: 10px;


    &--img img{
      border-radius: 10px;
      width: 100%;
      height: 440px;

    }
  }

  &--videos {
    -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
    border-radius: 10px;

    iframe {
      height: 440px;
      width: 100%;
      border-radius: 10px;
    }
  }


  &--content {
    
    padding-top: 40px;

    &--head {

      display: flex;
      align-items: center;
      justify-content: space-between;


      h2 {
        color: #1A2B48;
        font-size: 28px;
        font-weight: 700;
      }

      &--category{
        background: #5CAF4C;
        padding: 12px 24px;
        border-radius: 50px;
        width: max-content;
        h3 {
          color: white;
        }

      }
    }

    &--description {
      padding-top: 20px;
      .content {
        line-height: 30px;

      }
      p{
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }

}






// FAQS

.section--faqs {

  &--title {
    font-size: 18px;
    font-weight: 600;
    color: #1A2B48;
  }
  &--desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }
}

.cgu--page {

  a {
    text-decoration: none;
    color: $c-main;
  }
  &--head {
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: $c-main;
      padding-bottom: 12px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  &--title {
    font-size: 18px;
    font-weight: 600;
    color: #1A2B48;
  }
  &--desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }
}


.collapse__tafwija .MuiButtonBase-root {
  background-color: #1194d5;
}