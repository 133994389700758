.tafwija--table {
  background-color: white;

  .MuiTableCell-head {
    color: white;
    border-bottom: 1px solid rgb(230 230 230);
  }

  .MuiTableRow-head {
    background-color: #1a2b48;
  }

  .MuiTableCell-root {
    font-family: "Montserrat", sans-serif;
    white-space: pre;
  }

  .collapsTable {
    padding: 15px 0;
  }

  .MuiIconButton-root {
    background-color: #03387D;

    &:hover {
      background-color: #1a2b48;
    }
    svg {
      color: white;
    }
  }
}

.c-main {
  color: #03387D;
}
