.wrapper {
  position: relative;
  z-index: 1;
  width: max-content;

  &--title {
    position: relative;
    z-index: 2;
    --minFontSize: 16px;
    --maxFontSize: 20px;
    --scaler: 2vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
    font-weight: 700;
  }

  &--title::after {
      content:'';
      position:absolute;
      width: 15%;
      border-radius: 15px;
      height: 4px;
      background: #ffb62c;
      bottom: -12px;
      left:0;
      margin:auto;
  }

  .primary {
    color: #03387D;
  }
  .white {
    color: #fff;
  }
}
