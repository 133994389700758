.carpool {
    background-color: #5CAF4C;
    -webkit-box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    -moz-box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    box-shadow: 0 0 10px -4px rgba(165, 165, 165, 0.17);
    padding: 8px 12px;
    height: max-content;
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    color: white;
    border-radius: 50px;
    @media(max-width: 800px) {
        padding: 8px 6px;
    }

    .img{
        width: 12px;
    }

    svg {
        font-size: 15px;
    }

    h4 {
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
        @media(max-width: 800px) {
            font-size: 10px;
            margin-left: 6px;
        }
    }
}