.section--booking {

    .booking--wrapper {
        padding: 10px;
        @media (max-width: 800px) {
            padding: 0;
        }
        h2 {
            --minFontSize: 18px;
            --maxFontSize: 24px;
            --scaler: 8vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 700;
            color: #03387D;
            margin-bottom: 30px;
        }

        .selectbox--item {
            margin-top: 10px;
            width: 100%;
        }

        &--bookingDate {
            padding-bottom: 12px;
        }

        &--item {
            margin-bottom: 20px;

            .titledark {
                color: #303030;
                font-size: 15px;
                margin-bottom: 8px;
            }
            .title {
                --minFontSize: 14px;
                --maxFontSize: 16px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 500;
                color: $c-main;
                margin-bottom: 10px;
            }
            
            label {
                font-size: 14px;
                font-weight: 500;
                color: #1a2b48;
            }
            h2 {
                --minFontSize: 14px;
                --maxFontSize: 16px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 700;
                color: #1a2b48;
                margin-bottom: 10px;
            }

            &--prices {
                overflow: auto;

                &--price {
                    background-color: #FEFBF6;
                    border: 1px solid transparent !important;
                    padding: 10px;
                    margin-bottom: 8px;
                    border-radius: 0;

                    &--head {

                        @media (max-width: 800px) {
                            .MuiTypography-body1 {
                                font-size: 13px;
                            }
                        }
                    }

                    &--body {

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media (max-width: 800px) {
                            flex-wrap: wrap;
                        }

                        h2.price {
                            font-size: 14px;
                            font-weight: 700;
                            color: #FFB62C;
                            margin-bottom: 0;
                        }

                        .price-label {
                            color: #636363;
                            margin-right: 6px;
                        }
   
                        input {
                            height: 30px;
                            border: 1px solid #D3D3D3;
                            padding: 0 10px;
                            background: white;
                            width: 40px;
                            border-radius: 4px;
                        }

                    }

                }

                #table {
                    border-collapse: collapse;
                    width: 100%;

                    .totalright {
                        text-align: right;
                    }
                    
                    td, th {
                    padding: 0 8px;
                    vertical-align: middle;
                    }
                    tr:nth-child(even){background-color: #f2f2f2;}
                    
                    tr:hover {background-color: rgb(236, 236, 236);}
                    
                    th {
                        padding-top: 12px;
                        padding-bottom: 12px;
                        text-align: left;
                        background-color: #ffffff;
                        color: #03387D;
                        font-size: 12px;
                     }

                     h2.price {
                         font-size: 14px;
                         font-weight: 700;
                         color: #FFB62C;
                         margin-bottom: 0;
                     }

                     input {
                         height: 30px;
                         border: 1px solid #D3D3D3;
                         padding: 0 10px;
                         width: 40px;
                         border-radius: 4px;
                     }
                }
            }
        }
    }


    .recap--booking {
        border-radius: 0;
        background: #fff;
        -webkit-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        -moz-box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.17);
        transition: 0.8s ease;
        width: 100%;
        height: fit-content;
        border-top: 3px solid #FFB62C;

        .MuiInputBase-adornedEnd {
            padding-right: 0;
        }
        // .Mui-disabled {
            .MuiInputAdornment-root {
            .btn--disabled {
                opacity: 0;

            }
        }
    
        &--heading {
            background-color: #03387D;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 20px 15px;
    
            h2 {
                --minFontSize: 16px;
                --maxFontSize: 24px;
                --scaler: 8vw;
                font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
                font-weight: 700;
                text-align: center;
                color: #ffffff;
            }
           
        }
    
        &--body { 
            &--p1 {  
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px ;
                border-bottom: 1px solid #E6E6E6;

                h3 {
                    font-size: 14px;
                    font-weight: 700;
                    color: #03387D;
                }

                .price {
                    font-size: 14px;
                    font-weight: 700;
                    color: #FFB62C;
                }
            }


            &--p2 { 
                padding: 16px ;
                border-bottom: 1px solid #E6E6E6;
                &--line { 
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 10px;

                    h6{
                        font-size: 14px;
                        font-weight: 400;
                    }
                    p{
                        font-size: 14px;
                        padding-top: 6px;
                        font-weight: 400;
                        color: #03387D;
                    }
                    ul li {
                        font-size: 14px;
                        line-height: 20px;
                    }
                    
                 }
            }


            &--p3 {  
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px ;

                h3 {
                    font-size: 14px;
                    font-weight: 700;
                    color: #03387D;
                }

                .price {
                    font-size: 14px;
                    font-weight: 700;
                    color: #FFB62C;
                }
            }


            &--p4 {  
                padding: 0 15px;
                margin-bottom: 15px;
                &--total { 
                    background-color: #F4F4F4;
                    border-radius: 8px;
                    padding: 15px;
                    p {
                        color: #5CAF4C;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;

                        span {
                            color: #FFB62C;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    
        &--footer {
            padding: 0 15px 30px 15px;
    
        }
    }
}
.tafwija--checkout--button {
    // width: 30%;
    margin: 0 auto;
    margin-top: 20px;
    @media (max-width: 800px) {
        width: 50%;
    }
    @media (max-width: 500px) {
        width: 70%;
    }
}


.thankyou--wrapper {
    width: 100%;

    &--image {
        text-align: center;
        padding: 20px 0 40px;
        width: 100%;
        img {
            height: 160px;
        }
    }

    &--title {
        text-align: center;
        h2 {
            --minFontSize: 14px;
            --maxFontSize: 18px;
            --scaler: 4vw;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
            font-weight: 600;
            line-height: 28px;
            color: #03387D;
        }
        
        p {
            font-size: 14px;
            color: #202429;
            line-height: 24px;

            a {
                text-decoration: none;
                color: #202429;
                font-weight: 500;
            }
        }
    }

    &--infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0;
        border-top: 1px solid #e8e8e8;
        padding-top: 16px;

        @media (max-width: 800px) {
            flex-wrap: wrap;
        }

      &--item {
        // width: 20%;
        @media (max-width: 800px) {
            width: 50%;
            margin-bottom: 15px;
        }

        @media (max-width: 500px) {
            width: 100%;
            margin-bottom: 15px;
        }

        &--head {
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 5px;
        }
        &--value {
            font-size: 12px;
            font-weight: 600;
        }

      }

    }
}


.info--bank {
    h2 {
        --minFontSize: 14px;
        --maxFontSize: 18px;
        --scaler: 4vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 800;
        color: #03387D;
        padding-bottom: 20px;
    }

    h3 {
        --minFontSize: 13px;
        --maxFontSize: 16px;
        --scaler: 4vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 500;
        color: #071b39;
        padding-bottom: 15px;
        line-height: 24px;

        span {
            color: #373737;
            font-weight: 400;

        }
    }
}